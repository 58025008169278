import _ from 'lodash';
import {Card, Col, Row, Typography} from 'antd';

import {Expense} from './Expense';
import {Expense as ExpenseType} from './types';

const {Text} = Typography;

type ExpenseGroupProps = {
    items: ExpenseType[];
    title: string;
}

export function ExpenseGroup({items, title}: Readonly<ExpenseGroupProps>) {
    const sum = _.sumBy(items, 'amount')

    return (
        <Col xs={24}>
            <Card
                size={'small'}
                className={'expense-group'}
                title={
                    <Row justify={'space-between'}>
                        <Text className={'p'} disabled>{title}</Text>
                        <Text className={'p'}>-{sum.toFixed(2)}</Text>
                    </Row>
                }
            >
                {_.map(items, (item: ExpenseType, id: string) => {
                    return <Expense item={item} key={id}/>;
                })}
            </Card>
        </Col>
    )
}

import {notification} from 'antd';
import {Dayjs} from 'dayjs';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {handleApiError} from 'pm-utils';

import {useCreateExpenseMutation, useDeleteExpenseMutation, useUpdateExpenseMutation} from '../api';
import {setExpenseListDate} from '../slice';
import {Expense} from '../types';

export function useSaveExpense(id: string) {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [createExpense] = useCreateExpenseMutation();
    const [updateExpense] = useUpdateExpenseMutation();

    async function saveExpense(expense: Partial<Expense>) {
        try {
            if (id === 'new') {
                await createExpense(expense as Expense).unwrap()
            } else {
                await updateExpense({...expense, id}).unwrap()
            }

            dispatch(setExpenseListDate((expense.date as Dayjs)?.toISOString()));
            notification.success({message: 'Expense saved'})
            navigate('/expenses');
        } catch (err) {
            handleApiError(err)
        }
    }

    return saveExpense;
}

export function useDeleteExpense(id: string) {
    const navigate = useNavigate()

    const [deleteExpenseMutation] = useDeleteExpenseMutation();

    async function deleteExpense() {
        try {
            await deleteExpenseMutation(id);

            notification.success({message: 'Expense deleted'})
            navigate('/expenses')
        } catch (err) {
            handleApiError(err)
        }
    }

    return deleteExpense;
}

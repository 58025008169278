import {DatePicker, Form} from 'antd';

export function Date() {
    return (
        <Form.Item
            label={'Date'}
            name={'date'}
            rules={[{required: true, message: 'Please input the date'}]}
        >
            <DatePicker id={'date'} inputReadOnly allowClear={false}/>
        </Form.Item>
    )
}

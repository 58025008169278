import {configureStore} from '@reduxjs/toolkit'

import {apiSlice} from 'pm-api';
import {loaderSlice} from 'pm-loader';

import config from '../../config';
import {expenseListSlice} from '../../pods/auth/expenses/slice';

export const store = configureStore({
    devTools:   config.isDev,
    reducer:    {
        [apiSlice.reducerPath]:  apiSlice.reducer,
        [expenseListSlice.name]: expenseListSlice.reducer,
        [loaderSlice.name]: loaderSlice.reducer,
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware()
            .concat(apiSlice.middleware)
    },
})

export type RootState = ReturnType<typeof store.getState>

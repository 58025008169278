import {useMemo} from 'react';
import {TypedUseSelectorHook, useSelector} from 'react-redux'
import {ActionCreatorsMapObject, bindActionCreators} from 'redux';

import {RootState, store} from './store'

export function useActions(actions: ActionCreatorsMapObject) {
    const {dispatch} = store;

    return useMemo(() => bindActionCreators(actions, dispatch), [actions, dispatch]);
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

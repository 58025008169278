import {notification} from 'antd';
import _ from 'lodash';

export function handleApiError(err: unknown) {
    console.error(err);

    const errMessage = _.get(err, 'data.message') || _.get(err, 'message', 'Unknown error');

    notification.error({
        message:     'Something went wrong',
        description: errMessage,
    })
}

import {createSlice} from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
    name:      'loader',
    initialState: {
        isLoading: false,
    },
    reducers:  {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    selectors: {
        getIsLoading: (state) => state.isLoading,
    },
});
export const {setIsLoading} = loaderSlice.actions;
export const {getIsLoading} = loaderSlice.selectors;

import dayjs from 'dayjs';
import {Button, Card, Layout, Typography, Row, Col} from 'antd';
import {MenuOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import _ from 'lodash';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppSelector} from 'pm-store';
import {Header} from 'pm-components';

import {ExpenseGroup} from './ExpenseGroup';
import {HeaderContent} from './HeaderContent';
import {useGetExpensesQuery} from './api';
import {SideMenu} from './SideMenu';
import {getExpenseListDate} from './slice';

const {Content} = Layout;
const {Text} = Typography;

export function ExpenseList() {
    const date = useAppSelector(getExpenseListDate)
    const [menuCollapsed, setMenuCollapsed] = useState(true)

    const {data: expenses, refetch} = useGetExpensesQuery({
        from: dayjs(date).startOf('month').toISOString(),
        to:   dayjs(date).endOf('month').toISOString(),
    });

    const dayToExpensesMap = _.groupBy(expenses, item => {
        return dayjs(item.date).format('DD.MM ddd');
    })

    const monthlyTotal = _.reduce(expenses, (total, item) => {
        return total - item.amount;
    }, 0);

    const navigate = useNavigate();

    return (
        <>
            <Header
                leftIcon={<MenuOutlined/>}
                onLeftClick={() => setMenuCollapsed(!menuCollapsed)}
                rightIcon={<ReloadOutlined/>}
                onRightClick={refetch}
            >
                <HeaderContent/>
            </Header>

            <SideMenu menuCollapsed={menuCollapsed}/>

            <Content onClick={() => setMenuCollapsed(true)}>
                <Row gutter={[0, 10]}>
                    <Col xs={24}>
                        <Card size={'small'}>
                            <Row justify={'space-between'}>
                                <Text className={'h2'} disabled>Monthly Total</Text>
                                <Text className={'h2'}>{monthlyTotal.toFixed(2)}</Text>
                            </Row>
                        </Card>
                    </Col>

                    {_.map(dayToExpensesMap, (expenses, title) => {
                        return <ExpenseGroup items={expenses} title={title} key={title}/>
                    })}
                </Row>

                <Button
                    className={'floating-btn floating-btn--right floating-btn--xl'}
                    type={'primary'}
                    shape={'circle'}
                    size={'large'}
                    icon={<PlusOutlined/>}
                    onClick={() => {navigate('new')}}
                />
            </Content>
        </>
    )
}

import {Spin} from 'antd';

import {getIsLoading} from 'pm-loader';
import {useAppSelector} from 'pm-store';

export function Loader() {
    const isLoading = useAppSelector(getIsLoading)
    const isSomeQueryPending = useAppSelector((state) => {
        return Object.values(state.api.queries).some(query => query?.status === 'pending')
    })

    const spinning = isLoading || isSomeQueryPending

    return <Spin spinning={spinning} fullscreen={true}/>
}

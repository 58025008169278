import {Form, InputNumber} from 'antd';

export function Amount() {
    return (
        <Form.Item
            label={'Amount'}
            name={'amount'}
            rules={[{required: true, message: 'Please input the amount'}]}
        >
            <InputNumber
                id={'amount'}
                inputMode={'decimal'}
                autoFocus={true}
                style={{textAlign: 'right'}}
                controls={false}
                precision={2}
                min={0}
                step={0.01}
                addonAfter={'€'}
            />
        </Form.Item>
    )
}

import {createBrowserRouter, Navigate} from 'react-router-dom';

import {Auth, ExpenseList, ExpenseDetails, Login} from './pods';

export const router = createBrowserRouter([
    {path: '/auth', element: <Login/>},
    {
        path:     '/auth',
        element:  <Auth/>,
        children: [
            {path: 'expenses', element: <ExpenseList/>},
            {path: 'expenses/:id', element: <ExpenseDetails/>},
        ],
    },
    {path: '*', element: <Navigate to={'/auth'}/>},
]);

import {Layout} from 'antd';
import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {useEnsureLoggedIn} from 'pm-auth';
import {handleApiError} from 'pm-utils';

export function Auth() {
    const location = useLocation();
    const ensureLoggedIn = useEnsureLoggedIn();

    useEffect(() => {
        ensureLoggedIn().catch(handleApiError);
    }, [location, ensureLoggedIn])

    return (
        <Layout className={'main-container'}>
            <Outlet/>
        </Layout>
    );
}

import {Col, Form, Radio, Space} from 'antd';
import {colors} from 'pm-colors';

import {getCategoryIcon} from '../../../utils';
import {ExpenseCategory} from '../../../types';

type CategoryItemProps = {
    category: ExpenseCategory;
}

export function CategoryItem({category}: Readonly<CategoryItemProps>) {
    const form = Form.useFormInstance();
    const selectedCategory = Form.useWatch('categoryId', form);
    const selected = selectedCategory === category.id;

    const categoryIconStyle = selected ? {
        color:           colors.white,
        backgroundColor: category.color,
    } : {};
    const categoryTitleStyle = selected ? {
        color:      colors.gray2,
        fontWeight: 'bold',
    } : {};

    return (
        <Col xs={6} sm={4} md={3} lg={2} className={'text-center category-item__holder'}>
            <Radio value={category.id} className={'category-item__radio'}>
                <Space size={2} direction={'vertical'} align={'center'} className={'category-item'}>
                    <div className={'category-item__icon-background'} style={categoryIconStyle}>
                        {getCategoryIcon(category.icon)}
                    </div>
                    <span className={'sub category-item__text'} style={categoryTitleStyle}>{category.title}</span>
                </Space>
            </Radio>
        </Col>
    )
}

import {LeftOutlined, DeleteOutlined} from '@ant-design/icons';
import {Button, Card, Col, Form, Layout, Row} from 'antd';
import {useParams, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'


import {Header} from 'pm-components';

import {useGetExpenseQuery} from '../api';
import {useDeleteExpense, useSaveExpense} from './hooks';
import * as Inputs from './inputs';

const {Content} = Layout;

dayjs.extend(weekday)
dayjs.extend(localeData)

export function ExpenseDetails() {
    const {id} = useParams();
    const navigate = useNavigate();

    const isNew = id === 'new';
    const {data: expense} = useGetExpenseQuery(id, {skip: isNew});

    const saveExpense = useSaveExpense(id!);
    const deleteExpense = useDeleteExpense(id!);

    const [form] = Form.useForm();

    if (!isNew && !expense) {
        return null;
    }

    return (
        <>
            <Header
                leftIcon={<LeftOutlined/>}
                onLeftClick={() => navigate('/expenses')}
                rightIcon={!isNew && <DeleteOutlined/>}
                onRightClick={deleteExpense}
                title={isNew ? 'Add' : 'Details'}
            />

            <Content className={'expense-details'}>
                <Form
                    form={form}
                    layout={'vertical'}
                    initialValues={{...expense, date: dayjs(expense?.date)}}
                    onFinish={saveExpense}
                >
                    <Row gutter={[0, 10]}>
                        <Col xs={24}>
                            <Card size={'small'}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={12}><Inputs.Amount/></Col>
                                    <Col xs={12}><Inputs.Date/></Col>
                                    <Col xs={24}><Inputs.Category/></Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Card size={'small'}>
                                <Col xs={24}><Inputs.Title/></Col>
                            </Card>
                        </Col>
                    </Row>

                    <Button
                        className={'floating-btn floating-btn--center'}
                        type={'primary'}
                        size={'large'}
                        block={true}
                        htmlType={'submit'}
                    >
                        Save
                    </Button>
                </Form>
            </Content>
        </>
    );
}

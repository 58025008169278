import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    date: new Date().toISOString(),
};

export const expenseListSlice = createSlice({
    name:      'expenseList',
    initialState,
    reducers:  {
        setExpenseListDate: (state, action) => {
            state.date = action.payload;
        },
    },
    selectors: {
        getExpenseListDate: (state) => state.date,
    },
});

export const {setExpenseListDate} = expenseListSlice.actions;
export const {getExpenseListDate} = expenseListSlice.selectors;

import 'antd/dist/reset.css';
import {Provider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'

import {store} from 'pm-store';
import {Loader} from 'pm-loader';

import './styles/main.scss'
import config from './config';
import {router} from './router';

SuperTokens.init({
    appInfo:    {
        appName:     'expense-tracker',
        apiDomain:   config.apiUrl,
        apiBasePath: '/v1/auth',
    },
    recipeList: [
        Session.init(),
        EmailPassword.init(),
    ],
});

export default function App() {
    return (
        <Provider store={store}>
            <Loader/>
            <RouterProvider router={router}/>
        </Provider>
    );
}

import {apiSlice} from 'pm-api';

import {Expense, ExpenseCategory} from '../types';

export const expensesApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        createExpense: build.mutation({
            query:           (expense: Expense) => ({
                url:    '/auth/expenses',
                method: 'POST',
                body:   expense,
            }),
            invalidatesTags: () => [{type: 'Expense', id: 'LIST'}],
        }),
        getExpenses:   build.query<Expense[], any>({
            query:        (query) => ({
                url:    '/auth/expenses',
                params: query,
            }),
            providesTags: (res = []) => [
                {type: 'Expense', id: 'LIST'},
                ...res.map(({id}) => ({type: 'Expense' as const, id})),
            ],
        }),
        getExpense:    build.query({
            query:        (id) => ({
                url: `/auth/expenses/${id}`,
            }),
            providesTags: (_res, _err, arg) => [{type: 'Expense', id: arg}],
        }),
        updateExpense: build.mutation({
            query:           (expense: Partial<Expense>) => ({
                url:    `/auth/expenses/${expense.id}`,
                method: 'PATCH',
                body:   expense,
            }),
            invalidatesTags: (res, err) => {
                if (err) {
                    return [];
                }
                return [{type: 'Expense', id: res?.id}]
            },
        }),
        deleteExpense: build.mutation({
            query:           (id: string) => ({
                url:    `/auth/expenses/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_res, err, arg) => {
                if (err) {
                    return [];
                }
                return [{type: 'Expense', id: arg}]
            },
        }),

        getExpenseCategories: build.query<ExpenseCategory[], void>({
            query: () => '/auth/expense-categories',
        }),
    }),
})

export const {
    useCreateExpenseMutation,
    useGetExpensesQuery,
    useGetExpenseQuery,
    useUpdateExpenseMutation,
    useDeleteExpenseMutation,

    useGetExpenseCategoriesQuery,
} = expensesApiSlice

const isDev = process.env.NODE_ENV !== 'production';

type Config = {
    isDev: boolean;
    apiUrl: string;
}

const config: Config = {
    isDev,
    apiUrl: process.env.REACT_APP_API_URL || 'https://et.mihailo-misic.com',
}

export default config;

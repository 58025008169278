import {
    AppstoreOutlined,
    CloudDownloadOutlined,
    LogoutOutlined,
    PieChartOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';

import {useLogOut} from 'pm-auth';

const {Sider} = Layout;

type SideMenuProps = {
    menuCollapsed: boolean;
}

export function SideMenu({menuCollapsed}: Readonly<SideMenuProps>) {
    const logOut = useLogOut()

    return (
        <Sider
            className={'side-menu'}
            trigger={null}
            collapsible={true}
            collapsed={menuCollapsed}
            collapsedWidth={0}
        >
            <Menu
                mode={'inline'}
                theme={'dark'}
                items={[
                    {key: 'Analytics', label: 'Analytics (soon)', icon: <PieChartOutlined/>},
                    {key: 'Categories', label: 'Categories (soon)', icon: <AppstoreOutlined/>},
                    {key: 'Export', label: 'Export (soon)', icon: <CloudDownloadOutlined/>},
                    {key: 'Settings', label: 'Settings (soon)', icon: <SettingOutlined/>},
                ]}
            />

            <Menu
                mode={'inline'}
                theme={'dark'}
                items={[
                    {
                        key:     'Log out',
                        label:   'Log out',
                        icon:    <LogoutOutlined/>,
                        onClick: logOut,
                    },
                ]}
            />
        </Sider>
    )
}

import {LockOutlined, MailOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, Layout, Typography} from 'antd';

import {useLogIn} from 'pm-auth';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Session from 'supertokens-web-js/recipe/session';

const {Content} = Layout;
const {Title} = Typography;

export function Login() {
    const logIn = useLogIn()
    const navigate = useNavigate()

    useEffect(() => {
        Session.doesSessionExist().then((isLoggedIn) => {
            if (isLoggedIn) {
                navigate('/auth/expenses')
            }
        });
    }, [navigate]);

    return (
        <Layout className={'main-container'}>
            <Content className={'login'}>
                <Card>
                    <Title className={'text-center'} level={3}>Login</Title>

                    <Form
                        layout={'vertical'}
                        initialValues={{remember: true}}
                        autoComplete="off"
                        onFinish={logIn}
                    >
                        <Form.Item
                            label={'Email'}
                            name={'email'}
                            rules={[{required: true, message: 'Please input the email'}]}
                        >
                            <Input prefix={<MailOutlined/>} type={'email'}/>
                        </Form.Item>

                        <Form.Item
                            label={'Password'}
                            name={'password'}
                            rules={[{required: true, message: 'Please input the password'}]}
                        >
                            <Input.Password prefix={<LockOutlined/>}/>
                        </Form.Item>

                        <Button
                            htmlType={'submit'}
                            type={'primary'}
                            size={'large'}
                            block={true}
                        >
                            Log in
                        </Button>
                    </Form>
                </Card>
            </Content>
        </Layout>
    )
}

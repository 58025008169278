import _ from 'lodash';
import Icon, {QuestionOutlined} from '@ant-design/icons';

import * as Icons from 'pm-icons';

export function getCategoryIcon(iconName: string) {
    const iconComponent = _.get(Icons, _.upperFirst(_.camelCase(iconName)));
    if (iconComponent) {
        return <Icon component={iconComponent} size={200}/>
    }

    return <QuestionOutlined/>
}

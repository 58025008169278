export const colors = {
    black: '#000000',
    gray1: '#141414',
    gray2: '#262626',
    gray3: '#595959',
    gray4: '#BFBFBF',
    gray5: '#F0F0F0',
    gray6: '#FAFAFA',
    white: '#FFFFFF',

    blue1: '#1890FF',
    blue2: '#69C0FF',
    blue3: '#BAE7FF',

    cyan1: '#13C2C2',
    cyan2: '#5CDBD3',
    cyan3: '#B5F5EC',

    green1: '#52C41A',
    green2: '#95DE64',
    green3: '#D9F7BE',

    magenta1: '#EB2F96',
    magenta2: '#FF85C0',
    magenta3: '#FFD6E7',

    orange1: '#FA8C16',
    orange2: '#FFC069',
    orange3: '#FFE7BA',

    purple1: '#722ED1',
    purple2: '#B37FEB',
    purple3: '#EFDBFF',

    red1: '#F5222D',
    red2: '#FF7875',
    red3: '#FFCCC7',

    yellow1: '#FADB14',
    yellow2: '#FFF566',
    yellow3: '#FFFFB8',
}

import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {DatePicker, Space, Typography} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useCallback, useState} from 'react';

import {useActions, useAppSelector} from 'pm-store';

import {getExpenseListDate, setExpenseListDate} from './slice';

const {Text} = Typography;

const actions = {setExpenseListDate};

export function HeaderContent() {
    const {setExpenseListDate} = useActions(actions);

    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const date = dayjs(useAppSelector(getExpenseListDate));
    const showYear = date.format('YYYY') !== dayjs().format('YYYY');

    const setDate = useCallback((value: Dayjs) => {
        setExpenseListDate(value?.toISOString());
    }, [setExpenseListDate]);

    return (
        <>
            <Space size={'large'}>
                <LeftOutlined onClick={() => setDate(date.subtract(1, 'month'))}/>
                <Text className={'h2 cursor-pointer'} onClick={() => setDatePickerOpen(true)}>
                    {date.format('MMM')}{showYear && ` - ${date.format('YYYY')}`}
                </Text>
                <RightOutlined onClick={() => setDate(date.add(1, 'month'))}/>
            </Space>

            <DatePicker
                value={date}
                className={'month-picker'}
                popupClassName={'month-picker__popup'}
                open={datePickerOpen}
                onChange={setDate}
                picker={'month'}
                onOpenChange={() => setDatePickerOpen(false)}
            />
        </>
    )
}

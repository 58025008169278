import {notification} from 'antd';
import {setIsLoading} from 'pm-loader';
import {handleApiError} from 'pm-utils';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {signIn} from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';

type SignInClickedProps = {
    email: string;
    password: string;
}

export function useLogIn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function logIn({email, password}: SignInClickedProps) {
        try {
            dispatch(setIsLoading(true));

            const response = await signIn({
                formFields: [
                    {id: 'email', value: email},
                    {id: 'password', value: password},
                ],
            })

            if (response.status === 'FIELD_ERROR') {
                response.formFields.forEach(formField => {
                    if (formField.id === 'email') {
                        notification.error({message: formField.error})
                    }
                })

                return
            }

            if (response.status === 'WRONG_CREDENTIALS_ERROR') {
                return notification.error({message: 'Email password combination is incorrect.'})
            }

            if (response.status === 'SIGN_IN_NOT_ALLOWED') {
                return notification.error({message: response.reason})
            }

            navigate('/auth/expenses');
        } catch (err: any) {
            handleApiError(err)
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    return logIn
}

export function useLogOut() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function logOut() {
        try {
            dispatch(setIsLoading(true));

            await Session.signOut();

            navigate('/auth');
        } catch (err) {
            handleApiError(err)
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    return logOut
}

export function useEnsureLoggedIn() {
    const navigate = useNavigate();

    async function ensureLoggedIn() {
        const userLoggedIn = await Session.doesSessionExist();

        if (!userLoggedIn) {
            navigate('/auth')
        }
    }

    return ensureLoggedIn
}

import {Button, Col, Layout, Row, Typography} from 'antd';
import {MouseEventHandler, ReactNode} from 'react';

const {Text} = Typography;
const {Header: AntHeader} = Layout;

type HeaderProps = {
    leftIcon?: ReactNode;
    onLeftClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>;
    rightIcon?: ReactNode;
    onRightClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>;
    title?: string;
    children?: ReactNode;
};

export function Header({
    leftIcon,
    onLeftClick,
    rightIcon,
    onRightClick,
    title,
    children,
}: HeaderProps) {
    return (
        <AntHeader>
            <Row wrap={false} className={'header'}>
                <Col xs={4} className={'header__left'}>
                    {leftIcon && <Button
                        icon={leftIcon}
                        className={'header__btn'}
                        size={'large'}
                        type={'text'}
                        onClick={onLeftClick}
                    />}
                </Col>
                <Col flex={'auto'} className={'header__center'}>
                    {title ? <Text className={'h2'}>{title}</Text> : children}
                </Col>
                <Col xs={4} className={'header__right'}>
                    {rightIcon && <Button
                        icon={rightIcon}
                        className={'header__btn'}
                        size={'large'}
                        type={'text'}
                        onClick={onRightClick}
                    />}
                </Col>
            </Row>
        </AntHeader>
    )
}

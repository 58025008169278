import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import {Col, Row, Space, Typography} from 'antd';

import {colors} from 'pm-colors';

import {getCategoryIcon} from './utils';
import {useGetExpenseCategoriesQuery} from './api';
import {Expense as ExpenseType} from './types';

const {Text} = Typography;

type ExpenseProps = {
    item: ExpenseType
}

export function Expense({item}: Readonly<ExpenseProps>) {
    const navigate = useNavigate();

    const {data: expenseCategories} = useGetExpenseCategoriesQuery();
    const category = _.find(expenseCategories, {id: item.categoryId});

    if (_.isEmpty(category)) {
        return null
    }

    const categoryIconStyle = {
        color:           colors.white,
        backgroundColor: category.color,
    };

    return (
        <Col xs={24} onClick={() => navigate(item.id as string)} className={'expense-item'}>
            <Row justify={'space-between'} align={'middle'}>
                <Space size={5}>
                    <div className={'category-item__icon-background'} style={categoryIconStyle}>
                        {getCategoryIcon(category.icon)}
                    </div>
                    <Text className={'p'}>{item.title || category.title}</Text>
                </Space>
                <Text className={'p'}>-{item.amount.toFixed(2)}</Text>
            </Row>
        </Col>
    );
}

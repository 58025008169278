import {Form, Radio, Row} from 'antd';
import _ from 'lodash';

import {useGetExpenseCategoriesQuery} from '../../../api';
import {CategoryItem} from './CategoryItem';

export function Category() {
    const {data: expenseCategories} = useGetExpenseCategoriesQuery();

    return (
        <Form.Item
            label={'Category'}
            name={'categoryId'}
            rules={[{required: true, message: 'Please choose a category'}]}
        >
            <Radio.Group name={'categoryId'} className={'expense-categories'}>
                <Row>
                    {_.map(expenseCategories, category => (
                        <CategoryItem category={category} key={category?.id}/>
                    ))}
                </Row>
            </Radio.Group>
        </Form.Item>
    )
}
